export default function drawerMenu() {
  const drawerButton = document.querySelector('.drawerButton');
  const headerNavWrap = document.querySelector('.headerNavWrap');
  const { body } = document;
  const desktopMatchMedia = window.matchMedia('(min-width: 1025px)');

  if (headerNavWrap === null || headerNavWrap === null) return;

  const toggleAction = () => {
    body.classList.toggle('_drawerOpen');
    headerNavWrap.classList.toggle('_open');
  };

  const removeClass = () => {
    body.classList.remove('_drawerOpen');
    headerNavWrap.classList.remove('_open');
  };

  drawerButton.addEventListener('click', () => {
    toggleAction();
  });

  const links = headerNavWrap.querySelectorAll('a');
  links.forEach((link) => {
    link.addEventListener('click', () => {
      toggleAction();
    });
  });

  desktopMatchMedia.addListener(removeClass);
}
