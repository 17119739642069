export default function fadeSlider() {
  document.querySelectorAll('.js-fadeSlider').forEach((element) => {
    const swiperSlideElements = element.querySelectorAll('.swiper-slide');

    if (swiperSlideElements.length < 2) {
      return;
    }
    // eslint-disable-next-line no-new
    new Swiper(element, {
      loop: true,
      effect: 'fade',
      speed: 1000,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
    });
  });
}
