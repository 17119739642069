export default function mvSlider() {
  document.querySelectorAll('.js-mvSlider').forEach((element) => {
    // eslint-disable-next-line no-new
    new Swiper(element, {
      loop: true,
      effect: 'fade',
      speed: 1000,
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
    });
  });
}
