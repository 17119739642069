import cssHasPseudo from 'css-has-pseudo/browser';
import drawerMenu from './drawerMenu';
import fadeSlider from './fadeSlider';
import mvSlider from './mvSlider';

cssHasPseudo(document);

document.addEventListener('DOMContentLoaded', () => {
  drawerMenu();
  fadeSlider();
  mvSlider();
});
